/* eslint-disable @typescript-eslint/no-explicit-any */
'use client';
import useConutryCode from '@/hooks/useConutryCode';
import useUTMDetail, { UtmType } from '@/hooks/useUTMDetail';
import { AppDispatch, AppState } from '@/store/configureStore';
import { connect } from 'react-redux';
import { addUser, updateUser, User, UserDetail } from '@/store/User/reducer';
import { Cart, addUpdateCart, CartDetail } from '@/store/Cart/reducer';
import { addCouponCode, updateCouponCode } from '@/store/Order/reducer';
import React, { createContext, useContext, useState } from 'react';
import { addUpdateCourse } from '@/store/App/reducer';
import { API_URL } from '@/constants';
import { CouponCode } from './ThankYouContextProvider';

type AppContextValue = {
  countryCode: string;
  countryName: string;
  callingCode: string;
  currency: string;
  region: string;
  timezone: string;
  utmDetail: UtmType[];
  addUpdateCourseDispatch?: (course: string, courseLevelId?: number) => void;
  addUserDispatch?: (user: User) => void;
  updateUserDispatch?: (user: User | null) => void;
  addUpdateCartDispatch?: (cart: Cart) => void;
  addCouponCodeDispatch?: (couponCode: string) => void;
  updateCouponCodeDispatch?: (couponCode: string | null) => void;
  userDetail: UserDetail;
  cartDetail: CartDetail;
  course: string | null;
  courseLevelId?: number;
  couponCode?: CouponCode | undefined;
  getCouponCode?: (courseName: string) => void;
};

type AppContextProviderProps = {
  children?: React.ReactNode;
  rest?: any;
};

const initialContextValue: AppContextValue = {
  countryCode: 'IN',
  countryName: 'india',
  callingCode: '+91',
  currency: 'INR',
  region: '',
  timezone: '',
  utmDetail: [],
  userDetail: null,
  cartDetail: null,
  course: null
};

const mapStateToProps = (state: AppState) => {
  return state; // Access the ToDo property correctly
};

const mapStateToDispatch = (dispatch: AppDispatch) => {
  return {
    addUpdateCourseDispatch: (course: string, courseLevelId = 0) =>
      dispatch(addUpdateCourse({ course, courseLevelId })),
    addUserDispatch: (user: User) => dispatch(addUser(user)),
    updateUserDispatch: (user: User | null) => dispatch(updateUser(user)),
    addUpdateCartDispatch: (cart: Cart) => dispatch(addUpdateCart(cart)),
    addCouponCodeDispatch: (couponCode: string) =>
      dispatch(addCouponCode({ couponCode })),
    updateCouponCodeDispatch: (couponCode: string | null) =>
      dispatch(updateCouponCode({ couponCode }))
  };
};

export const AppContext = createContext<AppContextValue>(initialContextValue);

const AppContextProvider: React.FC<AppContextProviderProps> = ({
  children,
  ...rest
}: AppContextProviderProps) => {
  const { countryCode, countryName, callingCode, currency, region, timezone } =
    useConutryCode();
  const [couponCode, setCouponCodes] = useState<CouponCode | undefined>(
    undefined
  );

  const utmDetail = useUTMDetail();

  const {
    addUserDispatch,
    updateUserDispatch,
    addUpdateCartDispatch,
    addUpdateCourseDispatch,
    addCouponCodeDispatch,
    updateCouponCodeDispatch,
    user: { userDetail },
    cart: { cartDetail },
    app: { course, courseLevelId }
  } = rest as any;

  const getCouponCode = (courseName: string) => {
    if (userDetail && userDetail.id) {
      setCouponCodes(undefined);
      const body = {
        courseName: courseName,
        userId: userDetail && userDetail.id
      };
      fetch(`${API_URL}/trial-class/getCourseCouponByUserId`, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: { 'Content-Type': 'application/json' }
      })
        .then(async (res) => {
          const result = await res.json();
          const couponCodesData = {
            couponCodeGrL1: result.couponCodeGrL1,
            couponCodeGrL2: result.couponCodeGrL2,
            couponCodeGrL3: result.couponCodeGrL3,
            couponCodeSoL1: result.couponCodeSoL1,
            couponCodeSoL2: result.couponCodeSoL2,
            couponCodeSoL3: result.couponCodeSoL3
          };
          if (result) {
            setCouponCodes(couponCodesData);
          } else {
            setCouponCodes(undefined);
          }
        })
        .catch(() => {
          setCouponCodes(undefined);
        });
    } else {
      setCouponCodes(undefined);
    }
  };

  return (
    <AppContext.Provider
      value={{
        countryCode,
        countryName,
        callingCode,
        currency,
        region,
        timezone,
        utmDetail,
        addUserDispatch,
        updateUserDispatch,
        addUpdateCartDispatch,
        addUpdateCourseDispatch,
        addCouponCodeDispatch,
        updateCouponCodeDispatch,
        userDetail,
        cartDetail,
        course,
        courseLevelId,
        couponCode,
        getCouponCode
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default connect(mapStateToProps, mapStateToDispatch)(AppContextProvider);

export const useAppContext = () => {
  const app = useContext(AppContext);
  return app;
};
