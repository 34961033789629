export interface MenuListProps {
  id: string;
  label: string;
  link: string;
}

export interface FooterMenuProps {
  id: string;
  name: string;
  list: MenuListProps[];
}

export const footerMenus: FooterMenuProps[] = [
  {
    id: '1',
    name: 'Policy',
    list: [
      {
        id: '11',
        label: 'Privacy Policy',
        link: '/privacy-policy'
      },
      {
        id: '12',
        label: 'Terms & Condition',
        link: '/terms-and-condition'
      },
      {
        id: '13',
        label: 'Disclaimer',
        link: '/disclaimer'
      },
      {
        id: '14',
        label: 'Cancellation and Refunds',
        link: '/cancellation-refunds'
      }
    ]
  },
  {
    id: '2',
    name: 'Useful Link',
    list: [
      {
        id: '21',
        label: 'Home',
        link: '/'
      },
      {
        id: '22',
        label: 'About',
        link: '/about-us'
      },
      {
        id: '23',
        label: 'Log in',
        link: '/login'
      },
      {
        id: '24',
        label: 'Sign up',
        link: '/register'
      }
    ]
  }
];
