import { getAllCookies, setCookies } from '@/utils/cookies';
import { useSearchParams } from 'next/navigation';

export type UtmType = {
  Attribute: string | undefined;
  Value: string | undefined;
};

const useUTMDetail = () => {
  const params = useSearchParams();

  const utmDetail: UtmType[] = [];
  const utmDetailKey = [
    'utm_source',
    'utm_medium',
    'utm_term',
    'utm_content',
    'utm_campaign',
    'gclid',
    'Organic_Source'
  ];
  const cookies = getAllCookies();
  utmDetailKey.forEach((utmKey) => {
    const value = params.get(utmKey) ?? cookies[utmKey];
    const decodedValue = value ? decodeURIComponent(value) : undefined;
    if (decodedValue) {
      setCookies(utmKey, decodedValue);
      utmDetail.push({
        Attribute: `mx_${utmKey}`,
        Value: decodedValue
      });
    }
  });
  return utmDetail;
};

export default useUTMDetail;
