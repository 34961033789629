import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type Order = {
  amount: number;
  currency: string;
  orderId: string;
  email: string;
  name: string;
  phone: string;
  course: string;
  country: string;
};

export type OrderDetail = Order | null;

export type OrderState = {
  orderDetail: OrderDetail;
  couponCode: string | null;
};

export const initialToDoState: OrderState = {
  orderDetail: null,
  couponCode: null
};

const slice = createSlice({
  name: 'Order',
  initialState: initialToDoState,
  reducers: {
    addOrder: (state: OrderState, action: PayloadAction<Order>) => {
      state.orderDetail = action.payload;
    },
    updateOrder: (state: OrderState, action: PayloadAction<Order>) => {
      state.orderDetail = action.payload;
    },
    addCouponCode: (
      state: OrderState,
      action: PayloadAction<{ couponCode: string }>
    ) => {
      state.couponCode = action.payload.couponCode;
    },
    updateCouponCode: (
      state: OrderState,
      action: PayloadAction<{ couponCode: string | null }>
    ) => {
      state.couponCode = action.payload.couponCode;
    }
  }
});

const { reducer } = slice;

export const { addOrder, updateOrder, addCouponCode, updateCouponCode } =
  slice.actions;

export default reducer;
