'use client';
import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { WhatsAppUrl, WhataAppMessage } from '@/constants';
import { footerMenus, FooterMenuProps, MenuListProps } from './constants';
import './styles.scss';
import CourseSelectionDialog from '@/components/common/CourseSelectionDialog';
import { isLoggedIn } from '@/utils';
import { useAppContext } from '@/contexts/AppContextProvider';

interface FooterProps {
  className?: string;
}

const Footer: React.FC<FooterProps> = ({ className }) => {
  const [open, setOpen] = useState(false);
  const { userDetail } = useAppContext();
  const sendMessage = () => {
    let url = `${WhatsAppUrl}/`;

    if (WhataAppMessage) {
      url += `?text=${encodeURI(WhataAppMessage)}`;
    }

    window.open(url, '_blank');
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [data, setData] = useState<string>();
  useEffect(() => {
    setData(location.pathname);
  }, [location.pathname]);

  return (
    <section
      className={`pt-20 pb-10 padding-space-set-mobile-view footer-content ${
        data === '/my-course' ||
        data === '/profile' ||
        data === '/order' ||
        data === '/privacy-privacy' ||
        data === '/terms-and-condition' ||
        data === '/disclaimer' ||
        data === '/cancellation-refunds'
          ? 'footer-top-border'
          : ''
      }
      ${data === '/vocals-live-course' ? 'footer-has-bottom-space' : ''}
      ${data === '/adults-live-course' ? 'footer-has-bottom-space' : ''}
      ${className}`}
    >
      <div className="section-container">
        <div className="grid grid-cols-3 gap-6 sm:gap-5 count-content">
          <div className="ContactUs">
            <h2>Contact</h2>
            <Link
              className="text-white"
              href="mailto:support@artfills.com"
              title="email"
            >
              <Image
                src="/Icon-zocial-email.png"
                alt="email"
                title="email"
                width={23}
                height={16}
                className="inline-block mr-3"
              />
              support@artfills.com
            </Link>
            <div className="flex items-center mt-4 mb-7 sm:mt-6 sm:mb-9 margin-set-25">
              <Link
                href="https://www.facebook.com/Artfillsedu/?_ga=2.55021922.879494431.1713784979-949683771.1712674412"
                className="cursor-pointer"
                target="_blank"
                title="facebook"
              >
                <Image
                  src="/Icon-awesome-facebook-f.png"
                  alt="facebook"
                  title="facebook"
                  width={17}
                  height={29}
                  className="mr-6"
                />
              </Link>
              <Link
                href="https://www.instagram.com/artfills.indianarts/?_ga=2.46611822.879494431.1713784979-949683771.1712674412"
                className="cursor-pointer"
                target="_blank"
                title="instagram"
              >
                <Image
                  src="/insta-icon.png"
                  alt="instagram"
                  title="instagram"
                  width={27}
                  height={27}
                  className="mr-6"
                />
              </Link>
              <Link
                href="https://www.youtube.com/channel/UCiRhEkDumDnrYx7TpNMgn2A?_ga=2.46611822.879494431.1713784979-949683771.1712674412"
                className="cursor-pointer"
                target="_blank"
                title="youtube"
              >
                <Image
                  src="/Icon-awesome-youtube.png"
                  alt="you-tube"
                  title="you-tube"
                  width={31}
                  height={22}
                  className="mr-6"
                />
              </Link>
            </div>
            <button
              className="recommendBtn p-3 rounded-xl text-white min-w-56 margin-bottom-set-50"
              onClick={sendMessage}
            >
              <p>Recommend</p>
              <p>Artfills to Friends and Family</p>
            </button>
          </div>
          <span>
            <Image
              src="/booking-form-bg.png"
              alt="Testimonial Divider"
              title="Testimonial Divider"
              width={289}
              height={289}
              className="mx-auto"
            />
          </span>

          {footerMenus.map((menu: FooterMenuProps) => (
            <div key={menu.id} className="policy margin-bottom-set-50">
              <h2>{menu.name}</h2>
              <ul className="text-2xl">
                {menu.list.map((m: MenuListProps) => (
                  <li key={m.id}>
                    {(!userDetail?.id && !isLoggedIn()) ||
                    (m.label !== 'Sign up' && m.label !== 'Log in') ? (
                      m.label === 'Sign up' ? (
                        <Link
                          href="#"
                          className="cursor-pointer"
                          onClick={(e) => {
                            e.preventDefault();
                            handleOpen();
                          }}
                        >
                          {m.label}
                        </Link>
                      ) : (
                        <Link href={m.link} title={m.label}>
                          {m.label}
                        </Link>
                      )
                    ) : null}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        <div className="footer-bottom-tag">
          <p>
            Made with<small className="px-1">♥</small>by Indian Artists
          </p>
        </div>
      </div>
      <CourseSelectionDialog open={open} handleClose={handleClose} />
    </section>
  );
};

export default React.memo(Footer);
