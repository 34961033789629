import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type Cart = {
  amount: number;
  level: string;
  course: string;
  category: string;
};

export type CartDetail = Cart | null;

export type CartState = {
  cartDetail: CartDetail;
};

export const initialState: CartState = {
  cartDetail: null
};

const slice = createSlice({
  name: 'cart',
  initialState: initialState,
  reducers: {
    addUpdateCart: (state: CartState, action: PayloadAction<Cart>) => {
      state.cartDetail = action.payload;
    }
  }
});

const { reducer } = slice;

export const { addUpdateCart } = slice.actions;

export default reducer;
