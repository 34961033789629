'use client';
import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { useRouter } from 'next/navigation';
import menuItems from './menuItems.json';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

interface Item {
  key: string;
  title: string;
  desc: string;
  link: string;
  active: boolean;
}

const MenuItems: React.FC = () => {
  const router = useRouter();
  const handleMenuItemClick = (menuLink: string) => {
    router.push(menuLink);
  };

  const activeMenuItems = menuItems.filter((item: Item) => item.active);

  return (
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items className="absolute overflow-hidden dropdownBg left-0 z-10 mt-2 w-max w-mobile-port origin-top-right rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        <div>
          {activeMenuItems.map((menuItem) => (
            <Menu.Item key={menuItem.key}>
              {({ active }) => (
                <a
                  href="javascript:void(0)"
                  onClick={() => handleMenuItemClick(menuItem.link)}
                  className={classNames(
                    active || location.pathname === menuItem.link
                      ? 'activeLink text-white'
                      : 'text-white',
                    'block px-5 py-2.5 text-xl border-b border-gray-700'
                  )}
                >
                  <span
                    className={classNames(
                      active || location.pathname === menuItem.link
                        ? 'orangeTextActive'
                        : 'orangeText'
                    )}
                  >
                    {menuItem.title}
                  </span>
                  {menuItem.desc}
                </a>
              )}
            </Menu.Item>
          ))}
        </div>
      </Menu.Items>
    </Transition>
  );
};

export default React.memo(MenuItems);
