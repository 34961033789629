import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type User = {
  id: string;
  name: string;
  email: string;
  phone: string;
  course: string;
  callingCode: string;
};

export type UserDetail = User | null;

export type UserState = {
  userDetail: UserDetail;
};

export const initialToDoState: UserState = {
  userDetail: null
};

const slice = createSlice({
  name: 'User',
  initialState: initialToDoState,
  reducers: {
    addUser: (state: UserState, action: PayloadAction<User>) => {
      state.userDetail = action.payload;
    },
    updateUser: (state: UserState, action: PayloadAction<UserDetail>) => {
      state.userDetail = action.payload;
    }
  }
});

const { reducer } = slice;

export const { addUser, updateUser } = slice.actions;

export default reducer;
