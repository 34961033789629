import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type AppState = {
  course: string | undefined;
  courseLevelId?: number;
};

export const initialAppState: AppState = {
  course: undefined
};

const slice = createSlice({
  name: 'App',
  initialState: initialAppState,
  reducers: {
    addUpdateCourse: (state: AppState, action: PayloadAction<AppState>) => {
      state.course = action.payload.course;
      state.courseLevelId = action.payload.courseLevelId;
    }
  }
});

const { reducer } = slice;

export const { addUpdateCourse } = slice.actions;

export default reducer;
