'use client';
import React from 'react';
import {
  Dialog,
  DialogHeader,
  DialogBody,
  List,
  ListItem,
  Typography
} from '@material-tailwind/react';
import { useRouter } from 'next/navigation';
import { useAppContext } from '@/contexts/AppContextProvider';

interface CourseSelectionDialogProps {
  open: boolean;
  handleClose: () => void;
}

const CourseSelectionDialog: React.FC<CourseSelectionDialogProps> = ({
  open,
  handleClose
}) => {
  const route = useRouter();
  const { addUpdateCourseDispatch } = useAppContext();

  const handleCourseSelection = (course: string) => {
    if (addUpdateCourseDispatch) {
      addUpdateCourseDispatch(course);
    }
    route.push(`/register?bookingPage=${course}`);
    handleClose();
  };

  return (
    <Dialog open={open} size="xs" handler={handleClose} className="dialog-wrap">
      <DialogHeader className="flex justify-between items-center dialog-header">
        <div className="flex items-center">
          <h5>Select Your Preferred Choice </h5>
        </div>
        <div className="close-icon" onClick={handleClose}>
          X
        </div>
      </DialogHeader>
      <DialogBody className="dialog-body p-5 text-center min-h-[180px] sm:min-h-[220px] flex items-center">
        <div className="w-full checkbox-list">
          <List>
            {/* <ListItem className="checkbox-list-item p-0 rounded-none hover:bg-transparent focus:bg-transparent active:bg-transparent">
              <label
                htmlFor="list-one"
                className="flex w-full cursor-pointer items-center px-0 py-3"
                onClick={() => handleCourseSelection('kathak-for-kids')}
              >
                <Typography color="blue-gray" className="font-medium">
                  Kathak- For Kids (Age: 6-12)
                </Typography>
              </label>
            </ListItem> */}
            <ListItem className="checkbox-list-item p-0 rounded-none hover:bg-transparent focus:bg-transparent active:bg-transparent">
              <label
                htmlFor="list-two"
                className="flex w-full cursor-pointer items-center px-0 py-3"
                onClick={() => handleCourseSelection('vocals-live-course')}
              >
                <Typography color="blue-gray" className="font-medium">
                  Vocals- For Adults (Age: 18 - 60+)
                </Typography>
              </label>
            </ListItem>
            <ListItem className="checkbox-list-item p-0 rounded-none hover:bg-transparent focus:bg-transparent active:bg-transparent">
              <label
                htmlFor="list-three"
                className="flex w-full cursor-pointer items-center px-0 py-3"
                onClick={() => handleCourseSelection('kathak-for-adults')}
              >
                <Typography color="blue-gray" className="font-medium">
                  Kathak- For Adults (Age: 18 - 60+)
                </Typography>
              </label>
            </ListItem>
          </List>
        </div>
      </DialogBody>
    </Dialog>
  );
};

export default CourseSelectionDialog;
